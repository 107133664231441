@use 'src/colors.scss';

.header-home {
  height: 80px;
  background: white;
  filter: drop-shadow(0 4px 16px rgb(0 0 0 / 5.9%));
  display: flex;
  padding: 0 50px;
  align-items: center;

  img {
    height: 37px;
  }

  .anticon-arrow-left {
    margin-right: 30px;
  }

  .anticon-arrow-left,
  .anticon-arrow-right {
    font-size: 20px;
  }

  .anticon-arrow-right {
    color: #999;
  }

  &_tabs {
    margin-right: auto;
    display: flex;

    &_tab {
      text-align: left;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #424242;

      .icon-component {
        margin-right: 14px;
      }

      &_active {
        color: colors.$accent-magenta;
      }

      &:hover {
        color: colors.$accent-magenta-shade;
      }

      &.disabled {
        color: #cecaca;
        pointer-events: none;

        svg {
          path {
            fill: #cecaca;
          }
        }
      }
    }

    &_divider {
      height: 64px;
      border: 1px solid colors.$background-gray;
    }
  }
}
