@use 'src/colors.scss';

.register-page {
  .content-form-title {
    margin-top: 36px;
    font-size: 24px;
    line-height: 28.13px;
  }

  .sign-up-error {
    margin-top: 16px;
    color: colors.$error-red;
    font-size: 13px;
  }

  .content-form {
    width: 574px;
    border-radius: 2px;
    box-shadow: 0 10px 24px 0 rgb(44 70 82 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    > form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .form-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .form-input {
          width: 412px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          & + .form-input {
            margin-top: 15px;
          }

          > input {
            margin-top: 0;
          }
        }
      }

      .checkbox-wrapper {
        margin-top: 10px;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;

        span {
          margin-left: 15px;
        }

        .checkbox {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }

        .checkbox + label {
          display: inline-flex;
          align-items: center;
          user-select: none;
        }

        .checkbox + label::before {
          content: '';
          display: flex;
          align-items: center;
          width: 18px;
          height: 18px;
          color: rgb(95 163 41 / 100%);
          border: 2px solid rgb(95 163 41 / 100%);
          border-radius: 0.25em;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
        }

        .checkbox:checked + label::before {
          border-color: rgb(95 163 41 / 100%);
          background-image: url('../../assets/icons/check.svg');
          background-size: 14px;
        }
      }
    }

    .footer {
      padding: 20px;
      text-align: center;

      .registration-button {
        font-size: 16px;
        width: 412px;
      }
    }
  }
}
