@use 'src/colors.scss';

.workspaces-sidebar {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 136px;
  padding: 24px 12px;
  border-radius: 0 20px 20px 0;
  background-color: #fff;
  box-shadow: 0 2px 12px rgb(0 0 0 / 7.8%);
  overflow-x: hidden;
  z-index: 999;

  > .title {
    font-size: 18px;
  }

  > .separator {
    height: 1px;
    width: 80%;
    margin: 16px 0;
    border-top: 1px solid colors.$background-gray;
  }

  .workspaces {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    .workspace {
      width: 100%;
      padding: 0 6px;

      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &.active {
        margin-bottom: 36px;
        font-weight: bold;
      }

      & > span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 18px;
        overflow: hidden;
      }
    }

    > .dibn-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
    }

    > .error-icon {
      font-size: 36px;
      color: colors.$error-red;
    }
  }

  > .add-workspace {
    margin-top: 20px;
    color: #626262 !important;
    border: none !important;

    .anticon {
      font-size: 20px;
    }
  }
}
