.object {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: white;

  .object-content {
    flex-grow: 1;
    padding: 2rem 1rem;
    overflow-y: scroll;
    overflow-x: hidden;

    .attachments {
      display: flex;
      margin-top: 2rem;

      .attachment {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 2rem;
        min-width: 250px;

        > button {
          margin-top: 1rem;
        }
      }

      .attachment-items {
        width: 100%;

        .attachment-item {
          display: flex;
          justify-content: space-between;

          & + .attachment-item {
            margin-top: 1rem;
          }

          .description,
          .link {
            min-width: 17rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:first-child) {
              margin-left: 2rem;
            }

            > span {
              margin-left: 1rem;
              cursor: pointer;
              font-size: 0.85rem;
            }

            .dibn-input {
              width: 100%;
            }
          }
        }

        .empty-attachments {
          font-size: 0.85rem;
          display: flex;
          align-items: center;
          min-height: 2.2rem;
        }
      }
    }

    .relations {
      margin-top: 30px;
      border: 1px solid #dedede;
      border-radius: 6px;
      box-shadow: 0 2px 12px 0 #00000014;
      padding: 10px 15px 35px;
    }
  }
}

.ant-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
