@use 'src/colors';

.dibn-select {
  &.ant-select {
    &:not(.ant-select-disabled):hover > .ant-select-selector {
      border-color: colors.$accent-magenta;
    }

    &:not(.ant-select-customize-input) {
      &.ant-select-focused:not(.ant-select-disabled) > .ant-select-selector {
        border-color: colors.$accent-magenta;
        box-shadow: 0 0 0 1px colors.$accent-magenta;
      }

      > .ant-select-selector {
        border-radius: 4px;
        transition: all 0.3s;
      }
    }
  }
}

.dibn-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: lighten(colors.$accent-magenta-shade, 35%);
  }
}
