.sub-objects {
  margin: 2rem 0 0;
  padding: 0;
  list-style: none;

  .add-sub-object {
    margin-left: 1rem;
  }

  .sub-object-category {
    margin-top: 1rem;
    font-size: 1rem;

    & + .sub-object-category {
      margin-top: 2rem;
    }

    > .sub-object-panel {
      background-color: white;
      box-shadow: 0 2px 12px 0 #00000014;
      font-size: 0.85rem;

      & + .sub-object-panel {
        margin-top: 1rem;
      }

      &.ant-collapse-item {
        border: 1px solid #dedede;
        border-radius: 6px;
      }

      .ant-collapse-header {
        align-items: center;

        &:hover,
        &[aria-expanded='true'] {
          .remove-sub-object {
            opacity: 1;
            pointer-events: auto;
          }
        }

        .ant-collapse-header-text {
          display: flex;
          align-items: center;
          width: 100%;
          margin-right: 2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-transform: capitalize;
        }

        .no-sub-object-name {
          font-style: italic;
          opacity: 0.5;
        }

        .remove-sub-object {
          margin-left: auto;
          opacity: 0;
          pointer-events: none;
        }
      }

      .relations {
        margin-top: 2rem;
        border: none;
        box-shadow: none;
        padding: 0 0 1rem;
      }
    }
  }
}
