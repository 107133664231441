@use 'src/colors.scss';

.object-header {
  height: 4.8rem;
  display: flex;
  border-bottom: 1px solid colors.$background-gray;

  .object-tab {
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: rgb(239 237 237 / 100%);
    border-bottom: 2px solid rgb(218 220 224 / 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;

    .tab-title {
      color: rgb(66 66 66 / 100%);
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }

    .tab-text {
      color: rgb(51 51 51 / 60%);
      font-size: 0.7rem;
      line-height: 1rem;
      font-weight: 400;
    }

    .tab-close {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  .active-tab {
    background-color: white;
    border-color: transparent;
  }
}
