@use 'src/colors.scss';

.object-footer {
  flex-shrink: 0;
  border-top: 1px solid colors.$background-gray;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2em;

  .object-footer-button {
    width: 4.5rem;
    height: 2rem;
    margin-left: 20px;
    font-size: 0.85rem;
  }

  .close-button {
    margin-left: 5rem;
    margin-right: 2rem;
  }
}
