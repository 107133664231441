@use 'src/colors.scss';

.dibn-button {
  &.ant-btn {
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    line-height: 14px;
    height: 34px;

    &-default:not(.ant-btn-dangerous) {
      color: colors.$accent-magenta;
      border: 1px solid colors.$accent-magenta;

      &:hover,
      &:focus {
        background: #fff;
        border-color: colors.$accent-magenta-shade;
        color: colors.$accent-magenta-shade;
      }
    }

    &.ant-btn-sm {
      height: 30px;
    }

    &.ant-btn-lg {
      height: 40px;
    }

    &[disabled],
    &.disabled {
      opacity: 0.5;
    }

    &.ant-btn-primary {
      background-color: colors.$accent-magenta;
      border: 1px solid colors.$accent-magenta;
      color: #fff;

      &[disabled],
      &.disabled {
        opacity: 0.5;

        &:hover,
        &:focus {
          color: #fff;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus {
        background: colors.$accent-magenta-shade;
        border-color: colors.$accent-magenta-shade;
      }
    }
  }
}
