@use 'src/colors.scss';

.auth-pages-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  > .header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0 50px;
    background: #fff;
    filter: drop-shadow(0 4px 16px rgb(0 0 0 / 5.9%));

    img {
      height: 37px;
    }
  }

  .information {
    flex-shrink: 0;
    width: 852px;
    margin: 62px 0 0 50px;
    border-bottom: 1.5px solid colors.$background-gray;
    max-width: 90%;

    > .title {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
    }

    .opportunities {
      font-size: 16px;
      padding-bottom: 5px;
      line-height: 21px;

      .opportunity {
        color: rgb(51 51 51 / 60%);
      }
    }
  }

  > .page-content {
    flex-grow: 1;
    position: relative;
    display: flex;
    overflow-y: auto;

    > .form-wrapper {
      margin: auto;
      padding: 32px 0;
    }

    .request-trial-button {
      position: absolute;
      top: 28%;
      left: 11%;
      height: 200px;
      width: 282px;
      padding: 15px 18px;
      background: #fff;
      border: 1px solid #dedede;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0 2px 12px rgb(0 0 0 / 7.8%);

      > .title {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;

        span {
          font-style: normal;
          font-weight: 550;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: #333;
        }

        .subtitle {
          font-size: 14px;
          line-height: 17px;
          opacity: 0.95;
        }
      }

      > img {
        width: 96px;
        height: 96px;
      }
    }

    .form-input {
      > .text-error {
        margin: 0;
      }

      .ant-input-password {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    > .page-content {
      flex-direction: column;
      align-items: center;
      overflow-y: auto;

      > .form-wrapper {
        margin: 0;
        padding: 20px 0;
      }

      .request-trial-button {
        position: static;
        margin: 10px 0;
      }
    }
  }
}
