@use 'src/colors.scss';

.login-page {
  .content-form-title {
    margin-top: 36px;
    font-size: 24px;
    line-height: 28px;
  }

  .login-error {
    margin-top: 16px;
    color: colors.$error-red;
    font-size: 13px;
  }

  .content-form {
    width: 499px;
    border-radius: 2px;
    box-shadow: 0 10px 24px 0 rgb(44 70 82 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    > form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .form-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 32px;

        .form-input {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 412px;
          margin-bottom: 0;

          & + .form-input {
            margin-top: 24px;
          }

          > input {
            margin-top: 0;
          }
        }
      }

      .login-submit-button {
        width: 412px;
        margin-top: 64px;
        font-size: 16px;
      }
    }

    .footer {
      width: 100%;
      margin-top: 24px;
      padding: 24px;
      text-align: center;
      border-top: 1px solid colors.$background-gray;

      .registration-button {
        font-size: 16px;
      }
    }
  }
}
