@use 'src/colors.scss';

.dibn-input {
  .ant-input-password,
  .ant-input {
    padding: 5px 12px;
    border: 1px solid colors.$input-border;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
      border-color: colors.$accent-magenta;
    }

    &:focus {
      border-color: colors.$accent-magenta;
      box-shadow: 0 0 0 1px colors.$accent-magenta;
    }
  }

  &.dibn-label {
    input,
    .ant-input-password,
    .ant-input {
      margin-top: 8px;
    }
  }

  .ant-input-password .ant-input {
    margin-top: 0;
    outline: none;
  }

  &.input-error {
    .ant-input:not([type='password']),
    .ant-input-password {
      border: 1px solid colors.$error-red;
    }

    .text-error {
      font-size: 12px;
      margin-top: 4px;
      font-weight: normal;
      color: colors.$error-red;
    }
  }

  .ant-input-affix-wrapper {
    height: 34px;
    border-radius: 4px;

    &:hover {
      border-color: colors.$accent-magenta;
    }

    &-focused {
      outline: none !important;
      box-shadow: 0 0 0 1px colors.$accent-magenta;
    }
  }
}
