@use 'src/colors.scss';

.dibn-textarea {
  &.ant-input {
    &:hover {
      border-color: colors.$accent-magenta;
    }

    &:focus {
      border-color: colors.$accent-magenta;
      box-shadow: 0 0 0 1px colors.$accent-magenta;
    }
  }
}
