@use 'src/colors.scss';

.relations {
  display: flex;
  flex-direction: column;
  height: auto;

  .refresh {
    margin: 0 2rem -0.8rem auto;
  }

  .relations-section {
    display: flex;
    flex-direction: column;

    & + .relations-section {
      margin-top: 2rem;
    }

    .relation-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid colors.$background-gray;

      > .title {
        font-size: 0.9rem;
        font-weight: 500;
        min-width: 13rem;
        text-transform: uppercase;
      }

      > .info {
        font-size: 0.85rem;
        font-weight: 400;
        color: rgb(51 51 51 / 60%);
        margin-left: 30px;
      }
    }

    .relation-body {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        height: 3rem;
        padding: 0 2rem 0 0.45rem;
        font-size: 0.85rem;
        border-bottom: 1px solid colors.$background-gray;

        .extra-button {
          opacity: 0;
          pointer-events: none;
        }

        &[aria-expanded='true'] {
          .extra-button {
            opacity: 1;
            pointer-events: auto;
          }
        }

        .ant-collapse-arrow {
          font-size: 0.8rem;
        }

        .highlight {
          b {
            font-weight: 700;
            color: #5fa329;
          }
        }
      }

      &__item {
        & > .ant-collapse-content > .ant-collapse-content-box {
          padding: 0;
          position: relative;

          &::before {
            display: block;
            position: absolute;
            left: 13px;
            top: -15px;
            content: '';
            height: calc(100% + 5px);
            width: 1px;
            border-left: 1px dashed #c4c4c4;
          }
        }

        .ant-collapse-header-text {
          display: block;
          margin-right: 2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 3rem;
          background-color: rgb(212 235 193 / 20%);
          border-bottom: 1px solid colors.$background-gray;
          padding: 0 2rem 0 3rem;
          transition: background-color 150ms;

          .actions {
            flex-shrink: 0;
            display: flex;
            gap: 0 23px;
            margin-left: 2rem;
            opacity: 0;
            transition: opacity 150ms;
          }

          &:hover {
            background-color: rgb(212 235 193 / 70%);

            & > .actions {
              opacity: 1;
            }
          }

          .data {
            flex-grow: 1;
            font-size: 0.85rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .short {
              width: 40%;
            }

            .long {
              width: 60%;
              margin-left: 2rem;
            }

            .short,
            .long {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              @supports (-webkit-line-clamp: 2) {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }

            &::before {
              display: block;
              position: absolute;
              left: -32px;
              top: 50%;
              content: '';
              height: 1px;
              width: 22px;
              border-top: 1px dashed #c4c4c4;
            }
          }
        }
      }
    }
  }
}
