@use 'src/colors.scss';

.dibn-loader {
  &.ant-spin {
    color: colors.$accent-magenta;

    .ant-spin-dot-item {
      background-color: colors.$accent-magenta;
    }
  }
}
