// Primary
$primary: #5fa329;

// Accent
$accent-magenta: #77d32f;
$accent-magenta-shade: #6dbc2f;

// Secondary
$error-red: #e4002b;

// Text
$text-primary: #2c4652;

// Lines
$input-border: rgb(44 70 82 / 20%);

// Background
$background-gray: #dadce0;
