@use 'src/colors.scss';

.workspace-tiles {
  > header {
    display: flex;
    margin-top: 60px;

    .tiles-header {
      width: 100%;
      border-bottom: 1px solid colors.$background-gray;

      > .title {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: black;
      }

      > .label {
        min-height: 40px;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 19px;
        color: rgb(51 51 51 / 60%);
      }

      &:first-of-type {
        width: 55%;
        margin-right: 85px;
      }

      &:last-of-type {
        width: 45%;
        margin-left: 85px;
      }
    }
  }

  .tiles-container {
    display: flex;

    .favorite-tiles {
      margin-right: 85px;
      width: 55%;
    }

    .all-tiles {
      margin-left: 85px;
      width: 45%;
    }

    .tiles-list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 36px;
    }

    .dibn-loader {
      margin: auto;
    }

    .error-icon {
      margin: auto;
      font-size: 36px;
      color: colors.$error-red;
    }
  }
}
