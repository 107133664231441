.object-field {
  display: flex;
  align-items: flex-start;

  > .label {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: 0.35rem 2rem 0 0;
    min-width: 250px;
  }

  > .value {
    flex: 1;

    > .date,
    > .combobox {
      width: 300px;

      &::placeholder {
        color: red;
      }
    }

    // To match inputs.
    > .read-only-value {
      line-height: 34px;
    }
  }
}
