.launchpad {
  display: flex;
  height: calc(100% - 80px);
  overflow: auto;

  > .workspaces-sidebar {
    margin-top: 4px;
  }

  > main {
    width: 100%;
    padding: 0 100px;
  }
}
