@use 'src/colors.scss';

.dibn-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 9px;
  color: colors.$text-primary;

  > span {
    color: colors.$accent-magenta;
    margin-left: 4px;
  }
}
