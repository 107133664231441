.item-home-drag {
  background: #fff;
  border: 1px solid #dedede;
  box-shadow: 0 2px 12px rgb(0 0 0 / 7.8%);
  width: 268px;
  height: 200px;
  padding: 16px;
  position: relative;
  border-radius: 10px;
  margin: 0 24px 24px 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &_title {
    font-size: 14px;
    line-height: 16px;
    color: #999;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &_name {
    margin-bottom: auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #333;

    &_img {
      margin: 12px 0;
      overflow: hidden;
    }
  }

  &_lock {
    display: flex;
  }

  &_edit {
    display: none;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &_img {
    height: 60px;
  }
}
